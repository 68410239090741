<template>
  <uikit-skeleton v-if="skeleton" class="real-estate-card" v-bind="$attrs" active loading />
  <div v-else class="real-estate-card" v-bind="$attrs" @click="goToAd">
    <div class="cover">
      <img v-if="hasImg" :src="imageSrc" :alt="title" loading="lazy" @error="imageLoaderErrorHandler" />
      <img
        v-else
        alt="Pas d'image"
        src="https://placehold.co/200?text=Horiz.io&font=open-sans"
        loading="lazy"
      />
    </div>
    <div class="description">
      <div class="price">{{ price }} €</div>
      <div class="infos">
        <span class="performance">{{ rendement }}% brut</span>
        <span class="city">{{ formatCity }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { UikitSkeleton } from '@hz/ui-kit'

const props = defineProps({
  ad: {
    type: Object,
    default: () => ({}),
  },
  skeleton: {
    type: Boolean,
    default: false,
  },
})

const imgInError = ref(false)
const hasImg = computed(
  () => !imgInError.value && !!props.ad?.images_url[0] && !props.ad.images_url[0].match(/^http\b/),
)
const imageSrc = computed(() => props.ad?.images_url[0])
const title = computed(() => props.ad.title ?? '')
const price = computed(() => props.ad?.prix?.toLocaleString())
const rendement = computed(() => props.ad?.rendement?.toLocaleString())
const formatCity = computed(() => props.ad?.ville[0]?.toUpperCase() + props.ad?.ville?.toLowerCase().slice(1))

const imageLoaderErrorHandler = () => {
  imgInError.value = true
}

const goToAd = () => {
  window.open(props.ad.permalien)
}
</script>

<style lang="less" scoped>
.real-estate-card {
  height: 136px;
  width: 224px;
  border-radius: 6px;
  position: relative;
  display: flex;
  color: var(--ds-color-white-100);
  cursor: pointer;

  .cover {
    position: absolute;
    top: 0;
    left: 0;

    height: 136px;
    width: 100%;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
    }
  }

  .description {
    height: 136px;
    width: 100%;
    position: relative;
    padding: 54px 0 0 16px;
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.6%, rgba(0, 0, 0, 0.7) 73.45%);
  }

  .infos {
    border-top: 1px solid var(--ds-color-gray-50);
    padding-top: 4px;
    width: fit-content;
  }

  .price {
    font-size: 26.047px;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);
    line-height: normal;
    padding-bottom: 4px;
  }

  .performance {
    font-size: 16px;
    font-style: normal;
    font-weight: var(--ds-weight-semi-bold);
  }

  .city {
    color: var(--ds-color-gray-50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:before {
      display: inline;
      content: '|';
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}
</style>
